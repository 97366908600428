<template>
  <div class="modal-content">
    <div class="modal-header justify-content-between">
      <h5 class="modal-title text-secondary"><strong>Layout</strong> Widget</h5>
      <button
        type="button"
        class="modal-custom-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <font-awesome-icon icon="xmark" />
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-lg-6">
          <h5 class="font-main text-secondary">Selecciona un widget</h5>
          <div class="list-group">
            <a
              @click="selectWidget(w)"
              v-for="(w, i) in widgets"
              :key="i"
              href="javascript:"
              class="list-group-item list-group-item-action"
              :class="w.id_objeto === selected_widget.id_objeto ? 'active' : ''"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <img class="img-contain img-obj-60" :src="w.imagen_widget" />
                </div>
                <div class="flex-grow-1 d-flex justify-content-between ms-3">
                  <h5 class="mb-0">{{ w.nombre_widget }}</h5>
                  <font-awesome-icon
                    icon="angle-right"
                    class="fs-3 d-none d-lg-inline"
                    :class="
                      w.id_objeto === selected_widget.id_objeto
                        ? 'text-white'
                        : 'text-secondary opacity-50'
                    "
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6 mt-3 mt-lg-0">
          <h5 class="font-main text-secondary">Widget seleccionado</h5>
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <img
                    class="img-obj img-obj-60"
                    :src="selected_widget.imagen_widget"
                  />
                </div>
                <div class="flex-grow-1 ms-3">
                  <h5 class="mb-0">{{ selected_widget.nombre_widget }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <h5 class="font-main text-secondary">Configurar widget</h5>
              <WeatherWidget
                v-if="selected_widget.id_objeto === 1"
                :loaded_ubicaciones="loaded_widget.ubicaciones_clima"
                @setSelectedLocations="setSelectedLocationsWeatherAPI"
              />
              <AccidentesWidget
                v-if="selected_widget.id_objeto === 3"
                :loaded_dias="loaded_widget.dias_accidente"
                @setSelectedDias="setSelectedDiasAccidentes"
              />
              <CountdownWidget
                v-if="selected_widget.id_objeto === 5"
                :countDownData="loaded_widget.cuenta_regresiva"
                @setSelectedTime="setSelectedCountdown"
              />
              <div v-if="showScreenTime" class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <h6
                        class="font-main border-bottom text-secondary pb-1 mb-3"
                      >
                        Tiempo en pantalla {{ selected_widget.nombre_widget }}
                      </h6>
                    </div>
                  </div>
                  <div style="height: 48px" class="row">
                    <div class="col-12 col-lg-8 d-flex align-items-center">
                      <div class="form-check form-switch form-switch-custom">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="switch-time"
                          v-model="timeSelected"
                        />
                        <label
                          class="form-check-label link-cursor"
                          for="switch-time"
                        >
                          Activar una duración
                        </label>
                      </div>
                    </div>
                    <div
                      v-if="timeSelected"
                      class="col-12 col-lg-4 d-flex align-items-center justify-content-end"
                    >
                      <button
                        @click="removeTime()"
                        class="btn btn-custom-color-white rounded-circle border"
                        type="button"
                      >
                        <font-awesome-icon icon="minus" />
                      </button>
                      <span
                        class="border rounded-2 py-1 px-2 mx-2 mw-100 text-center"
                        >{{ count }} s</span
                      >
                      <button
                        @click="addTime()"
                        class="btn btn-custom-color-white rounded-circle border"
                        type="button"
                      >
                        <font-awesome-icon icon="plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        ref="closeModal"
        class="btn btn-custom-color-white rounded-pill mw-100"
        data-bs-dismiss="modal"
      >
        <i class="fa-solid fa-angle-left pe-1"></i>
        Volver
      </button>
      <button
        @click="addWidgetToChannel()"
        type="button"
        :disabled="invalidWidgetInfo"
        class="btn btn-custom-color-blue rounded-pill mw-100"
      >
        Añadir
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
const WeatherWidget = () => import("./widgets/WeatherWidget.vue");
const AccidentesWidget = () => import("./widgets/AccidentesWidget.vue");
const CountdownWidget = () => import("./widgets/CountdownWidget.vue");
export default {
  props: {
    rank: String,
    widgets: Array,
  },
  components: {
    WeatherWidget,
    AccidentesWidget,
    CountdownWidget,
  },

  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      timeSelected: false,
      selected_widget: {},
      loaded_widget: {},
      selectedLocationsWeatherAPI: [],
      selectedLocationsCountdown: [],
      selectedDiasSinAccidentes: "",
      count: 4,
    };
  },

  computed: {
    ...mapState("tvCorporativaModule", ["channelLayout"]),

    showScreenTime() {
      //["Weather API", "Días sin accidente", "RRSS", "Cuenta Regresiva"]
      const widgetsWithoutTime = [1, 3, 4, 5];
      if (widgetsWithoutTime.includes(this.selected_widget.id_objeto)) {
        return false;
      } else return true;
    },
    invalidWidgetInfo() {
      switch (this.selected_widget.id_objeto) {
        case 1:
          return this.selectedLocationsWeatherAPI.length === 0;
        case 3:
          return this.selectedDiasSinAccidentes === "";
        case 5:
          return this.selectedLocationsCountdown.reduce((prev, curr) => {
            if (prev === true) return true;
            const { titulo, fecha, hora } = curr;
            if (titulo.trim() === "" || !fecha || !hora) return true;
            return false;
          }, false);
        default:
          return false;
      }
    },
  },

  mounted() {
    this.setWidgetFromState();
  },

  methods: {
    ...mapActions("tvCorporativaModule", [
      "setChannelLayoutWidgets",
      "getChannelStateFromLS",
    ]),

    setWidgetFromState() {
      this.getChannelStateFromLS();
      if (this.channelLayout.widgets[this.rank]) {
        this.setLoadedWidget();
      } else {
        this.selectWidget(this.widgets[0]);
      }
    },

    setLoadedWidget() {
      // eslint-disable-next-line no-undef
      const channelLayoutClon = structuredClone(
        this.channelLayout.widgets[this.rank]
      );

      this.loaded_widget = channelLayoutClon;
      this.selected_widget = channelLayoutClon;

      if (this.selected_widget.id_objeto === 1) {
        // Weather API
        this.selectedLocationsWeatherAPI = this.loaded_widget.ubicaciones_clima;
      }
      if (this.selected_widget.id_objeto === 5) {
        this.selectedLocationsCountdown = this.loaded_widget.cuenta_regresiva;
      }

      this.timeSelected = true;
      this.count = this.channelLayout.widgets[this.rank].tiempo_ejecucion;
    },

    addWidgetToChannel() {
      const widgetH = {
        1: () =>
          (this.selected_widget.ubicaciones_clima =
            this.selectedLocationsWeatherAPI),
        3: () =>
          (this.selected_widget.dias_accidente =
            this.selectedDiasSinAccidentes),
        5: () =>
          (this.selected_widget.cuenta_regresiva =
            this.selectedLocationsCountdown),
      };

      const widgetHandler = widgetH[this.selected_widget.id_objeto];
      if (widgetHandler) {
        widgetHandler();
      }

      const payload = {
        widget: this.selected_widget,
        rank: this.rank,
        tiempo_ejecucion: this.count,
      };
      this.setChannelLayoutWidgets(payload);
      this.closeWidget();
      this.$refs.closeModal.click();
    },

    closeWidget() {
      this.cleanSelectedWidget();
      this.$emit("closeWidget");
    },

    selectWidget(widget) {
      this.selected_widget = widget;
    },

    cleanSelectedWidget() {
      this.timeSelected = false;
      this.selected_widget = {};
      this.count = 4;
    },

    addTime() {
      this.count++;
    },

    removeTime() {
      if (this.count > 0) {
        this.count--;
      }
    },

    setSelectedLocationsWeatherAPI(emitted_locations) {
      this.selectedLocationsWeatherAPI = emitted_locations;
    },
    setSelectedCountdown(emitted_countdown) {
      this.selectedLocationsCountdown = [emitted_countdown];
    },

    setSelectedDiasAccidentes(dias) {
      this.selectedDiasSinAccidentes = dias;
    },
  },
};
</script>
